import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Input } from "@nulogy/components";
import { inputConfig } from "../../playgrounds/input";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const Example = makeShortcode("Example");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Input`}</h1>
    <blockquote>
      <p parentName="blockquote">{`An input field that users type into.`}</p>
    </blockquote>
    <Playground {...inputConfig} mdxType="Playground" />
    <h2>{`Variations`}</h2>
    <h3>{`Disabled`}</h3>
    <Example componentName="input" scope={{
      Input
    }} example="DisabledInput" mdxType="Example" />
    <h3>{`Error`}</h3>
    <Example componentName="input" scope={{
      Input
    }} example="ErrorInput" mdxType="Example" />
    <h3>{`With prefix and suffix`}</h3>
    <Example componentName="input" scope={{
      Input
    }} example="PrefixSuffixInput" mdxType="Example" />
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={inputConfig.props} mdxType="PropsTable" />
    <h2>{`Content Guidelines`}</h2>
    <p>{`Whenever possible match the width of the input field with the expected length of the input. If that is not possible then fill the entire width of the container.`}</p>
    <h2>{`Props`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      